import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-desk-footer',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './desk-footer.component.html',
  styleUrls: ['./desk-footer.component.scss'],
})
export class DeskFooterComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

  up(){
    window.scroll(0, 0);
  }

}
