<!-- <section class="banner-formas-de-pago">
  <div class="part-cont f-row" style="width: 25%;">
    <i class="fa-light fa-truck-fast truckin"></i> 
    <div class="txt-cont-1">
      <span class="span-up">¡ENVÍO GRATIS!</span>
      <span class="span-down">a partir de <span class="stronger">$2,000.00</span> de compra.</span>
    </div>
  </div>
  <div class="part-cont f-col">
    <span class="txt-p1-2">3 MESES SIN INTERESES</span>
    <span class="txt-p2-2">a partir de <span class="stronger">$3,000.00</span> de compra con PayPal y tarjeta de crédito.</span>
  </div>
  <div class="part-cont f-row">
    <img style="width: 6em; height: auto;" src="assets/img/homeNewIcons/formapago.webp" alt="">
    <div class="rin-col">
      <img class="pagos-icons" src="assets/img/homeNewIcons/tarjetas.webp" alt="">
      <span class="txt-pagos">Tarjetas de crédito y débito</span>
    </div>
    <div class="rin-col">
      <img class="pagos-icons" src="assets/img/homeNewIcons/transfrencia.webp" alt="">
      <span class="txt-pagos">Transferencia</span>
    </div>
    <div class="rin-col">
      <img class="pagos-icons" src="assets/img/homeNewIcons/paypal.webp" alt="">
      <span class="txt-pagos">PayPal</span>
    </div>
    <div class="rin-col">
      <img class="pagos-icons" src="assets/img/homeNewIcons/oxxo.webp" alt="">
      <span class="txt-pagos">OXXO</span>
    </div>
  </div>
</section> -->


<section class="desk-header">


  <div class="pagos-cont">
    <div class="first-cont">
      <div class="txt-cont">
        <span class="txt" style="color:#9BBCAA">A PARTIR DE $2,000.00</span>
        <span class="txt" style="color:#9BBCAA">¡ENVIO GRATIS!</span>
        <i class="fa-solid fa-truck trokita"></i>
      </div>
    </div>
    <div class="second-cont">
      <span class="prin" style="color:#9BBCAA">6 MESES SIN INTERESES</span>
      <span class="secon" style="color:#9BBCAA">EN COMPRAS MAYORES A <strong>$3,000.00</strong> CON PAYPAL Y TARJETAS DE
        CRÉDITO</span>
    </div>

    <div class="second-cont">
      <span class="prin" style="color:#9BBCAA">FORMAS DE PAGO</span>
      <!-- <span class="secon">EN COMPRAS MAYORES A <strong>$3,000.00</strong> CON PAYPAL Y TARJETAS DE CRÉDITO</span> -->

      <div style="display: flex; flex-direction: row;">
        <div class="metodo-cont" style="margin-right: 10px;">
          <i class="fa-solid fa-credit-card grap" style="color:#9BBCAA"></i>
          <span class="txt" style="color:#9BBCAA">&nbsp;TARJETA DE CRÉDITO Y DÉBITO&nbsp;</span>
        </div>
        <div class="metodo-cont" style="margin-right: 10px;">
          <i class="fa-brands fa-paypal grap" style="color:#9BBCAA"></i>
          <span class="txt" style="color:#9BBCAA">&nbsp; PAY PAL&nbsp;</span>
        </div>
        <div class="metodo-cont" style="margin-right: 10px;">
          <i class="fa-solid fa-shop grap" style="color:#9BBCAA"></i>
          <span class="txt" style="color:#9BBCAA">&nbsp;OXXO&nbsp;</span>
        </div>
        <div class="metodo-cont">
          <i class="fa-solid fa-money-bill-transfer grap" style="color:#9BBCAA"></i>
          <span class="txt" style="color:#9BBCAA">&nbsp;TRANSFERENCIA&nbsp;</span>
        </div>
      </div>
    </div>
    <!-- <div class="third-cont">
      <span class="prin">FORMAS DE PAGO</span>
      <div class="contcarmov">
        <div class="mov">
          <div class="metodo-cont">
            <i class="fa-solid fa-credit-card grap"></i>
            <span class="txt">TARJETA DE CRÉDITO Y DÉBITO</span>
          </div>
          <div class="metodo-cont">
            <i class="fa-brands fa-paypal grap"></i>
            <span class="txt">PAY PAL</span>
          </div>
          <div class="metodo-cont">
            <i class="fa-solid fa-shop grap"></i>
            <span class="txt">OXXO</span>
          </div>
          <div class="metodo-cont">
            <i class="fa-solid fa-money-bill-transfer grap"></i>
            <span class="txt">TRANSFERENCIA</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="up" #upMenu>
    <a [routerLink]="['/']" routerLinkActive="router-link-active">
      <img src="assets/img/logo-lide.png" alt="" class="loguin">
    </a>
    <div class="nav-opcs-cont">
      <a [routerLink]="['/categoria/10/categorías-por-tipo-de-producto']" routerLinkActive="router-link-active"
        class="opi">PRODUCTOS</a>
      <a [routerLink]="['/categoria/11/categorías-por-marca']" routerLinkActive="router-link-active"
        class="opi">MARCAS</a>
      <a [routerLink]="['/categoria/12/categorías-por-técnica']" routerLinkActive="router-link-active"
        class="opi">TÉCNICAS</a>
      <a [routerLink]="['/paquetes']" routerLinkActive="router-link-active" class="opi">PAQUETES</a>
      <!-- <a [routerLink]="['/catalogo-paquetes/1829']" routerLinkActive="router-link-active"  class="opi">Pa'Quete Pagues la Vacación</a> -->
      <!--<a [routerLink]="['/']" routerLinkActive="router-link-active"  class="opi tagin-mesrosa">MES ROSA</a>
      <a [routerLink]="['/catalogo/1419']" routerLinkActive="router-link-active"  class="opi tagin-halloween"><i class="fa-solid fa-ghost grap"></i> HALLOWEEN</a>-->
      <a [routerLink]="['/catalogo/155/lo-más-nuevo']" routerLinkActive="router-link-active" class="opi">PRODUCTOS
        NUEVOS</a>
      <!-- <a [routerLink]="['/catalogo/2412/promociones-halloween']" routerLinkActive="router-link-active" style="background-color: #ffb197;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;PROMOCIONES HALLOWEEN&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <a [routerLink]="['/promociones-cricut']" style="background-color:#1DABD1;color:white;font-weight: bold;"
        routerLinkActive="router-link-active" class="opi">&nbsp;&nbsp;&nbsp;&nbsp;PROMOCIONES
        CRICUT&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <!-- <a [routerLink]="['/catalogo/2621/promociones-brother']" routerLinkActive="router-link-active" style="background-color: #FFB5BA;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;PROMOCIONES BROTHER&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <!-- <a [routerLink]="['/catalogo/2639/mega-fin-crafter']" routerLinkActive="router-link-active" style="background-color: #1dabd1;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;MEGA FIN CRAFTER&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <!-- <a [routerLink]="['/catalogo/2056']" routerLinkActive="router-link-active" style="background-color: rgb(97, 180, 235);color:white"  class="opi">PROMOCIONES MAYO</a> -->
      <a [routerLink]="['/catalogo/123/outlet']" routerLinkActive="router-link-active" class="opi">OUTLET</a>
      <a [routerLink]="['/catalogo/2742/super-fin']" routerLinkActive="router-link-active" style="background-color: #ffb097;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;¡SUPER FIN!&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <a [routerLink]="['/blog']" routerLinkActive="router-link-active" class="opi">BLOG</a>
      <a [routerLink]="['/software-de-equipos']" routerLinkActive="router-link-active" class="opi">SOFTWARE DE
        EQUIPOS</a>
      <!-- <a [routerLink]="['/catalogo/2136/promociones-cricut']" routerLinkActive="router-link-active" style="background-color: #1DABD1;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;PROMOCIONES CRICUT&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <!-- <a [routerLink]="['/catalogo/2254/promociones-para-stickers']" routerLinkActive="router-link-active" style="background-color: #BBD0DE;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;¡PROMOCIONES PARA STICKERS!&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <!-- <a [routerLink]="['/catalogo/2137/para-tus-agendas-2025']" routerLinkActive="router-link-active" style="background-color: #79C0EA;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;¡PARA TUS AGENDAS 2025!&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <a [routerLink]="['/catalogo/2141/nuevos-paquetes']" routerLinkActive="router-link-active"
        style="background-color: #FFB5BA;color:white;font-weight: bold;" class="opi">&nbsp;&nbsp;&nbsp;&nbsp;NUEVOS
        PAQUETES&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <!-- <a [routerLink]="['/catalogo/2315/back-to-school']" routerLinkActive="router-link-active" style="background-color: #79C0EA;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;BACK TO SCHOOL&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <!-- <a [routerLink]="['/catalogo/2363/dale-un-toque-de-brillo']" routerLinkActive="router-link-active" style="background-color: #ffae93;color:white;font-weight: bold;"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;¡DALE UN TOQUE DE BRILLO!&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
      <a [routerLink]="['/distribuidor']" routerLinkActive="router-link-active" class="opi">DISTRIBUIDOR</a>
      <a [routerLink]="['/lidepuntos']" routerLinkActive="router-link-active" class="opi">LIDEPUNTOS</a>
      <a [routerLink]="['/cursos-gratis']" routerLinkActive="router-link-active" class="opi">CURSOS GRATIS</a>
      <a [routerLink]="['/nosotros']" routerLinkActive="router-link-active" class="opi">NOSOTROS</a>
      <a href="https://soporte.craftroom.mx/hc/es-419" target="blank" class="opi">SOPORTE</a>
      <a [routerLink]="['/contacto']" routerLinkActive="router-link-active" class="opi">CONTACTO</a>
      <a [routerLink]="['/compra-y-recoge']" style="background-color:#85E0D9;color:white;font-weight: bold;"
        routerLinkActive="router-link-active" class="opi">&nbsp;&nbsp;&nbsp;&nbsp;¡COMPRA Y
        RECOGE!&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <a [routerLink]="['/facturacion']" routerLinkActive="router-link-active"
        class="opi">&nbsp;&nbsp;&nbsp;&nbsp;Facturación&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <!-- <a [routerLink]="['/creative-gift']" style="background-color:#009ec9;color:white;font-weight: bold;" routerLinkActive="router-link-active"  class="opi">&nbsp;&nbsp;&nbsp;&nbsp;CREATIV-E GIFT&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
    </div>
    <div class="nav-cont">
      <div class="tagin-conts">
        <div class="tagin-mesrosa">
          <span class="tag-txt">Mes rosa</span>
        </div>
        <div class="tagin-halloween">
          <i class="fa-solid fa-ghost grap"></i>
          <span class="tag-txt">Halloween</span>
        </div>
      </div>
      <div class="btn-nav" (click)="handleNav()">
        <div class="cros1"></div>
        <div class="cros2"></div>
        <div class="blankin-cont">
          <span class="blankin-txt">Navegar</span>
          <i class="fa-solid fa-sailboat blankin-icon"></i>
        </div>
      </div>
      <!--<div class="desp-cont" #nav_cont>
        <div class="fix">
          <a class="opc">
            PRODUCTOS
          </a>
          <a class="opc">
            MARCAS
          </a>
          <a class="opc">
            TÉCNICAS
          </a>
          <a class="opc">
            PAQUETES
          </a>
          <a class="opc">
            OUTLET
          </a>
          <a class="opc">
            BLOG
          </a>
          <a class="opc">
            SOFTWARE DE EQUIPOS
          </a>
          <a class="opc">
            DISTRIBUIDOR
          </a>
          <a class="opc">
            LIDEPUNTOS
          </a>
          <a class="opc">
            CURSOS GRATIS
          </a>
          <a class="opc">
            NOSOTROS
          </a>
          <a class="opc">
            SOPORTE
          </a>
          <a class="opc">
            CONTACTO
          </a>
        </div>
      </div>-->
    </div>
    <div class="third-cont">
      <!-- Contenedor de buscador -->
      <div class="busc-cont">
        <i class="fa-solid fa-magnifying-glass grapi"></i>
        <form class="form-busc-cont">
          <button class="btn-busc" (click)="onEnterCel(searchInputCel.value)">Buscar</button>
          <input type="text" class="buscainp" placeholder="Busque en nuestro catalogo" autocomplete="off" id="pabuscar"
            (ngModelChange)="onSearchChange($event)" [(ngModel)]="txtBuscador" #searchInput #searchInputCel
            [ngModelOptions]="{standalone: true}">
        </form>
        <div class="results-cont-google" *ngIf="txtBuscador">
          <button class="clearing" (click)="cleanBusk()">Limpiar</button>
          <div class="links-cont-results" #focus>
            @if (results && results.items) {
            <a *ngFor="let item of results.items" class="prod-cont" [routerLink]="[ '/'+item.link ]"
              (click)="cleanBusk()">
              <div class="pp pp-cat" *ngIf="item.link.includes('/catalogo/')">
                <i class="fa-sharp fa-solid fa-layer-group"></i>
                <span class="txt-pp">Categoría</span>
              </div>
              <div class="pp pp-prod" *ngIf="!item.link.includes('/catalogo/') && !item.link.includes('/paquetes/')">
                <i class="fa-sharp fa-solid fa-tags"></i>
                <span class="txt-pp">Producto</span>
              </div>
              <div class="pp pp-paq" *ngIf="item.link.includes('/paquetes/')">
                <i class="fa-sharp fa-regular fa-boxes-stacked"></i>
                <span class="txt-pp">Paquete</span>
              </div>
              <div class="info-prod">
                <div class="img-cont-prod">
                  <img class="img-prod" *ngIf="item.pagemap?.cse_image" [src]="item.pagemap.cse_image[0].src"
                    alt="{{ item.title }}">
                </div>
                <div class="txt-cont">
                  <span class="title-prod">{{ item.title }}</span>
                  <div class="price-cont-prod"
                    *ngIf="item.pagemap.metatags[0]['product:price'] && item.pagemap.metatags[0]['product:price'] && item.pagemap.metatags[0]['product:price'] >'0'">
                    <span class="price-prod">$ {{ item.pagemap.metatags[0]['product:price'] | number:'1.2-2' }} <span
                        class="curr">MXN</span></span>
                  </div>
                </div>
              </div>
            </a>
            }
          </div>
          <div class="btns-cont">
            @if (results.queries.previousPage) {
            <button class="prev-forw-btns" (click)="prevPage()">Atrás</button>
            }
            @if (results.queries.nextPage) {
            <button class="prev-forw-btns" (click)="nextPage()">Siguiente</button>
            }

          </div>
        </div>
      </div>
      <!-- Contenedor de buscador -->
      <div class="oth-opts-cont">
        <div class="opt-cont"><!-- style="padding-bottom: 1em; padding-left: 1em;" -->
          <i class="fa-solid fa-circle-user grapi-up"></i>
          <i class="fa-solid fa-angle-down grapi-down"></i>
          <!--<img src="assets/icon/usuario.png" style="width: 1.4em; height: auto;">-->
          <div class="opts-acount-cont">
            <a *ngIf="!usuario" [routerLink]="['/login']" class="opas">
              <span><i class="fa-solid fa-right-to-bracket"></i> Iniciar Sesión</span>
            </a>
            <a *ngIf="!usuario" class="opas" (click)="modalReg()">
              <span><i class="fa-solid fa-user-plus"></i> Crear Cuenta</span>
            </a>
            <a *ngIf="usuario" class="opas" [routerLink]="['/perfil/0']">
              <span><i class="fa-solid fa-circle-user"></i> Mi Cuenta</span>
            </a>
            <a *ngIf="usuario" class="opas" [routerLink]="['/wishlist']">
              <span><i class="fa-brands fa-gratipay"></i> Wishlist</span>
            </a>
            <a *ngIf="usuario" class="opas" (click)="close()">
              <span><i class="fa-solid fa-circle-xmark"></i> Cerrar sesión</span>
            </a>
          </div>
        </div>
        <a class="opt-cont" [routerLink]="['/cart']" style="text-decoration: none;">
          <i class="fa-solid fa-bag-shopping grapi-up"></i>
          <!--<img src="assets/icon/compras.png" style="width: 1.5em; height: auto;">-->
          <span class="grapi-down">{{totalItems}}</span>
        </a>
        <a *ngIf="usuario" class="opt-cont" [routerLink]="['/wishlist']" style="text-decoration: none;">
          <i class="fa-solid fa-heart grapi-up"></i>
          <span class="grapi-down">{{listaWishlist.length}}</span>
        </a>
      </div>
    </div>
  </div>
  <div class="down">
    <span class="horario"><span>Horario Chihuahua:</span> <span>Lunes a Viernes: 8:00 am - 6:00 pm</span></span>
    <div class="cont-rs">
      <a class="redin" href="https://www.facebook.com/Lideart/" target="blank">
        <i class="fa-brands fa-facebook-f"></i>
      </a>
      <a class="redin" href="https://twitter.com/lideart" target="blank">
        <i class="fa-brands fa-twitter"></i>
      </a>
      <a class="redin" href="https://www.instagram.com/lideart/" target="blank">
        <i class="fa-brands fa-instagram"></i>
      </a>
      <a class="redin" href="https://ar.linkedin.com/company/lideart" target="blank">
        <i class="fa-brands fa-linkedin-in"></i>
      </a>
      <a class="redin" href="https://www.pinterest.com.mx/lideart/" target="blank">
        <i class="fa-brands fa-pinterest-p"></i>
      </a>
      <a class="redin" href="https://www.youtube.com/c/Lideart" target="blank">
        <i class="fa-brands fa-youtube"></i>
      </a>
      <a class="redin" [routerLink]="['/reviews']" routerLinkActive="router-link-active">
        <i class="fa-solid fa-comment"></i>
      </a>
    </div>
    <div class="infocont">
      <a href="https://web.whatsapp.com/send?l=en&phone=+526146051278" target="blank" class="opc">
        <i class="fa-brands fa-whatsapp"></i>
        <span>(614) 605 1278</span>
      </a>
      <a href="tel:+526144326122" class="opc">
        <i class="fa-solid fa-phone" style="font-size: .9em;"></i>
        <span>Tel (614) 432 6122</span>
      </a>
      <a href="tel:+526146051278" class="opc">
        <i class="fa-solid fa-phone" style="font-size: .9em;"></i>
        <span>Cel (614) 605 1278</span>
      </a>
      <a href="mailto:contacto@lideart.com.mx" class="opc">
        <i class="fa-solid fa-envelope"></i>
        <span>contacto&#64;lideart.com.mx</span>
      </a>
    </div>
    <div class="info-cont">
      <span>Información de contacto <i class="fa-solid fa-caret-down"></i></span>
      <div class="opcs-cont">
        <a href="https://web.whatsapp.com/send?l=en&phone=+526146051278" target="blank" class="opc">
          <i class="fa-brands fa-whatsapp"></i>
          <span>(614) 605 1278</span>
        </a>
        <a href="tel:+526144326122" class="opc">
          <i class="fa-solid fa-phone" style="font-size: .9em;"></i>
          <span>(614) 432 6122</span>
        </a>
        <a href="tel:+526146051278" class="opc">
          <i class="fa-solid fa-phone" style="font-size: .9em;"></i>
          <span>(614) 605 1278</span>
        </a>
        <a href="mailto:contacto@lideart.com.mx" class="opc">
          <i class="fa-solid fa-envelope"></i>
          <span>contacto&#64;lideart.com.mx</span>
        </a>

      </div>
    </div>
  </div>
</section>

<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>


<script type="text/javascript" charset="utf-8" async defer>


  function escondeHijos(hijo1, hijo2, padre) {

    if (hijo2 != "") {
      padre.children[0].className = (document.getElementById(hijo2).style.display == 'none' ? 'fas fa-chevron-up' : 'fas fa-chevron-down')
      document.getElementById(hijo2).style.display = (document.getElementById(hijo2).style.display == 'none' ? 'block' : 'none')
      if (document.getElementById('resultadosMarcas').style.display != "none") {
        document.getElementById('contResultadosMarcas').children[0].children[0].click()
      }
      if (document.getElementById('resultadosCategorias').style.display != "none") {
        document.getElementById('contResultadosCategorias').children[0].children[0].click()
      }
    } else {
      padre.children[1].className = (document.getElementById(hijo1).style.display == 'none' ? 'fas fa-chevron-up' : 'fas fa-chevron-down')
    }
    document.getElementById(hijo1).style.display = (document.getElementById(hijo1).style.display == 'none' ? 'block' : 'none')
  }
  if (window.outerWidth < 769) {
    escondeHijos('contResultadosMarcas', 'contResultadosCategorias', document.getElementById('tituloFiltros'))
  } else {
    document.getElementById('contResultadosMarcas').children[0].children[0].click()
    document.getElementById('contResultadosCategorias').children[0].children[0].click()
  }
</script>