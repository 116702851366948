<section class="footer-desk">
  <div class="reds-cont">
    <div class="cont-rs">
      <a class="redin" href="https://www.facebook.com/Lideart/" target="blank">
        <i class="fa-brands fa-facebook-f"></i>
      </a>
      <a class="redin" href="https://twitter.com/lideart" target="blank">
        <i class="fa-brands fa-twitter"></i>
      </a>
      <a class="redin" href="https://www.instagram.com/lideart/" target="blank">
        <i class="fa-brands fa-instagram"></i>
      </a>
      <a class="redin" href="https://ar.linkedin.com/company/lideart" target="blank">
        <i class="fa-brands fa-linkedin-in"></i>
      </a>
      <a class="redin" href="https://www.pinterest.com.mx/lideart/" target="blank">
        <i class="fa-brands fa-pinterest-p"></i>
      </a>
      <a class="redin" href="https://www.youtube.com/c/Lideart" target="blank">
        <i class="fa-brands fa-youtube"></i>
      </a>
      <a class="redin" [routerLink]="['/reviews']" routerLinkActive="router-link-active" >
        <i class="fa-solid fa-comment"></i>
      </a>
    </div>
  </div>
  <div class="rest-cont">
    <span class="warn">Todos los precios ya incluyen IVA</span>
    <div class="col-cont">
      <div class="colt">
        <span class="titl">PRODUCTOS</span>
        <a [routerLink]="['/lidepuntos']" routerLinkActive="router-link-active"  class="linka">Lidepuntos</a>
        <a [routerLink]="['/catalogo/123/outlet']" routerLinkActive="router-link-active"  class="linka">Productos en oferta</a>
        <a [routerLink]="['/categoria/10/categorías-por-tipo-de-producto']" routerLinkActive="router-link-active"  class="linka">Productos</a>
        <a [routerLink]="['/categoria/11/categorías-por-marca']" routerLinkActive="router-link-active"  class="linka">Marcas</a>
        <a [routerLink]="['/categoria/12/categorías-por-técnica']" routerLinkActive="router-link-active"  class="linka">Técnicas</a>
        <a [routerLink]="['/paquetes']" routerLinkActive="router-link-active"  class="linka">Paquetes</a>
      </div>
      <div class="colt">
        <span class="titl">NUESTRA EMPRESA</span>
        <a [routerLink]="['/terminos-politicas', 1]" routerLinkActive="router-link-active" class="linka" (click)="up()">Terminos de servicio</a>
        <a [routerLink]="['/terminos-politicas', 2]" routerLinkActive="router-link-active" class="linka" (click)="up()">Política de reembolso</a>
        <a [routerLink]="['/terminos-politicas', 3]" routerLinkActive="router-link-active" class="linka" (click)="up()">Política de privacidad</a>
        <a [routerLink]="['/terminos-politicas', 4]" routerLinkActive="router-link-active"  class="linka" (click)="up()">Política de envíos</a>
        <a [routerLink]="['/terminos-politicas', 5]" routerLinkActive="router-link-active"  class="linka" (click)="up()">Política de Garantía</a>
        <a [routerLink]="['/nosotros']" routerLinkActive="router-link-active"  class="linka" (click)="up()">Nosotros</a>
        <a [routerLink]="['/distribuidor']" routerLinkActive="router-link-active"  class="linka" (click)="up()">Distribuidor</a>
        <a [routerLink]="['/contacto']" routerLinkActive="router-link-active"  class="linka" (click)="up()">Contacto</a>
      </div>
      <div class="colt">
        <span class="titl">INFORMACIÓN</span>
        <a [routerLink]="['/terminos-politicas', 1]" routerLinkActive="router-link-active" class="linka" (click)="up()">Terminos de servicio</a>
        <a [routerLink]="['/terminos-politicas', 2]" routerLinkActive="router-link-active" class="linka" (click)="up()">Política de reembolso</a>
        <a [routerLink]="['/terminos-politicas', 3]" routerLinkActive="router-link-active" class="linka" (click)="up()">Política de privacidad</a>
      </div>
    </div>
  </div>
</section>