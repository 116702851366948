import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import Fuse from 'fuse.js';
import { catchError, of } from 'rxjs';
import { Usuario } from '../../Models/usuario';
import { ApiBuscadorGoogleService } from '../../services/api-buscador-google.service';
import { ApiProductosService } from '../../services/api-productos.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CarritoServiceService } from '../../services/carrito-service.service';
import { BuscadorService } from '../../services/handle-buscador.service';
import { HandleInpSearchService } from '../../services/handle-inp-search.service';
import { LoginService } from '../../services/login.service';
import { ServiciowishlistService } from '../../services/serviciowishlist.service';
import { searchResult } from '../../types/googleSearch';

@Component({
  selector: 'app-desk-header',
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './desk-header.component.html',
  styleUrls: ['./desk-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeskHeaderComponent  implements OnInit {
  ESCAPE_KEYCODE = 27;
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {    
      if ( this.txtBuscador != '' ) {
        this.txtBuscador = '';
      }
    }
  }

  @ViewChild('nav_cont') nav_cont!:ElementRef<HTMLInputElement>;
  @ViewChild('focus', { read: ElementRef }) tableInput!: ElementRef;

  @ViewChild('upMenu') upMenu!:ElementRef<HTMLInputElement>;
  sticky: boolean = false;
  elementPosition: any;

  @ViewChild('pistasCont') pistasCont!:ElementRef<HTMLInputElement>;
  @ViewChild('ud1') ud1!:ElementRef<HTMLInputElement>;
  pistasContIs = false;

  @ViewChild('cardMarcas') cardMarcas!:ElementRef<HTMLInputElement>;
  @ViewChild('ud2') ud2!:ElementRef<HTMLInputElement>;
  cardMarcasIs = false;

  @ViewChild('cardCategorias') cardCategorias!:ElementRef<HTMLInputElement>;
  @ViewChild('ud3') ud3!:ElementRef<HTMLInputElement>;
  cardCategoriasIs = false;

  nav_contIs = false;
  cargando = false;
  respaq:any;
  usuario!: Usuario|null;
  datosUsuario: any = [];
  nombre!: string;
  apellido!: string;
  public totalItems: number = 0;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  resultadosBusqueda:any=[];
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  listaWishlist: any =[];

  @ViewChild('ulElement') ulElement!: ElementRef;
  private observer!: MutationObserver;
  public banderaLi : boolean = false;
  
  public totalLoaded : boolean = false;

  public totalResultados: number = 0; //Me cae mal tener que hacer esto para parsear string a number en angular... 

  startIndex: number = 1;
  sortOrder: string = '';
  results: searchResult = {
    kind: "",
    url: {
      type: "",
      template: ""
    },
    queries: {
      request: [
        {
          title: "",
          totalResults: "",
          searchTerms: "",
          count: 0,
          startIndex: 0,
          inputEncoding: "",
          outputEncoding: "",
          safe: "",
          cx: ""
        }
      ]
    },
    context: {
      title: ""
    },
    searchInformation: {
      searchTime: 0,
      formattedSearchTime: "",
      totalResults: "",
      formattedTotalResults: ""
    },
    items: []
  };
  query: string = '';

  constructor(
    private renderer : Renderer2,
    private apiProductos : ApiProductosService,
    private router : Router,
    private shoppingCart : CarritoServiceService,
    public usuarioService : LoginService,
    private authService : AuthenticationService,
    private wishlist : ServiciowishlistService,
    private cdr: ChangeDetectorRef,
    private txtBuscadorService : HandleInpSearchService,
    private handleLoad : BuscadorService,
    public customSearchService: ApiBuscadorGoogleService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    /*afterRender(()=>{ 
      
    }) */
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {

      this.handleLoad.handleLoad$.subscribe((state:any)=>  {
        this.totalLoaded = state;
      });

      this.usuarioService.usuario.subscribe(res =>{
        if (!res) return
        this.usuario = res;
        if (this.usuario) {
          this.checalo();
        }
      });

      this.shoppingCart.cartItems.subscribe(d=>{
        this.totalItems = d.length;
        this.cdr.detectChanges();
      });
    }

    /*this.apiProductos.getProductos().subscribe((response) => {
      this.productos = response;
    });*/
    
    if (this.usuario) {
      this.wishlist.recuperaWishlist(this.usuario.usuario).subscribe((response) => {
        this.listaWishlist = response;
       
      });
    }
  }

  scrollUp(): void {
    this.tableInput.nativeElement.scrollTop = 0;
  }

  onEnterCel(searchQuery: string){
    if (searchQuery.length<1) return
    this.txtBuscador = '';
    const currentUrl = this.router.url;
    sessionStorage.setItem('initialUrl', currentUrl);
    this.txtBuscadorService.setTxtBuscador(searchQuery);
    this.router.navigate(['/google-search/'+searchQuery]);
    //this.router.navigate(['/google-search'], { queryParams: { query: searchQuery } });
  }

  handlePistas(){
    if (this.pistasContIs == false) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '0em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-down');

      this.pistasContIs = true;
    }else if (this.pistasContIs == true) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '8em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '1em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-up');

      this.pistasContIs = false;
    }
  }

  handleCard1(){
    if (this.cardMarcasIs == false) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '12em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '.1em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-down');

      this.cardMarcasIs = true;
    }else if (this.cardMarcasIs == true) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '3em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-up');

      this.cardMarcasIs = false;
    }
  }

  handleCard2(){
    if (this.cardCategoriasIs == false) {
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'height', '12em');
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'padding', '.1em');
      this.renderer.removeClass(this.ud3.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud3.nativeElement, 'fa-chevron-down');

      this.cardCategoriasIs = true;
    }else if (this.cardCategoriasIs == true) {
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'height', '3em');
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud3.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud3.nativeElement, 'fa-chevron-up');

      this.cardCategoriasIs = false;
    }
  }

  ngAfterViewInit(){
    this.elementPosition = this.upMenu.nativeElement.offsetTop;

    this.initMutationObserver();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['txtBuscador']) {
      this.checkForListItems();
    }
  }


  private resetSearch(){
    this.results.items = [];
    this.results.searchInformation = {
      formattedSearchTime: "",
      formattedTotalResults: "0",
      totalResults: "0",
      searchTime: 0.0,
    }
  }

  onSearchChange(query: string) {
    this.query = query;
    if(query.length < 1){
      this.resetSearch();
      return
    }
    this.customSearchService.search(this.query, this.startIndex, this.sortOrder).pipe(
      catchError(error => {
        console.error('Error al realizar busqueda:', error);
        // Retorna un observable vacío o algún valor por defecto
        return of({ items: [], searchInformation: {
          formattedSearchTime: "",
          formattedTotalResults: "0",
          totalResults: "0",
          searchTime: 0.0,
        } });
      })
    ).subscribe(response=> {
      response = response as searchResult
      // console.log(response)
      this.totalResultados = Number(response.searchInformation.totalResults)
      this.results = response;
        if (!response.items){
          return 
        }
        this.results.items = this.results.items?.map((item: any) => ({
          ...item,
          link: this.extractPath(item.link)
        }));
    });
  }
  
  nextPage(): void {
    if(!this.results.queries.nextPage) return
    const startIndex = this.results.queries.nextPage[0].startIndex
    this.scrollUp();
    this.customSearchService.search(this.query, startIndex, this.sortOrder).subscribe(response => {
      if (response)
      this.results = response;
      this.results.items = this.results.items?.map((item:any) => ({
        ...item,
        link: this.extractPath(item.link)
      }));
    });
  }

  prevPage(): void {
    if(!this.results.queries.previousPage) return
    const startIndex = this.results.queries.previousPage[0].startIndex
    this.scrollUp();
    this.customSearchService.search(this.query, startIndex, this.sortOrder).subscribe(response => {
      this.results = response;
    if (!response.items) return
      this.results.items = this.results.items?.map((item:any) => ({
        ...item,
        link: this.extractPath(item.link)
      }));
    });
  }

  private extractPath(url: string): string {
    try {
      const parsedUrl = new URL(url);
      return `/${parsedUrl.pathname}`;
    } catch (error) {
      // console.error('Invalid URL:', url);
      return url;
    }
  }

  cleanBusk(){
    this.txtBuscador = '';
  }

  checalo() {
    if(this.usuario)
    this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
      this.datosUsuario = data;


      this.nombre = this.datosUsuario.nombre;
      this.apellido = this.datosUsuario.apellido;
    })
  }

  close(){
    this.usuarioService.logout();
    this.authService.logout();
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll(){
      const windowScroll = window.pageYOffset;
    
      if(windowScroll >= this.elementPosition){
        this.renderer.setStyle(this.upMenu.nativeElement, 'position', 'fixed');
      } else {
        this.renderer.setStyle(this.upMenu.nativeElement, 'position', 'relative');
      }
    }

  handleNav(){
    if (this.nav_contIs == false) {
      this.renderer.setStyle(this.nav_cont.nativeElement, 'top', '5.3em');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'visibility', 'visible');

      this.nav_contIs = true;
    } else if (this.nav_contIs == true) {
      this.renderer.setStyle(this.nav_cont.nativeElement, 'top', '-25em');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'opacity', '0');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'visibility', 'hidden');

      this.nav_contIs = false;
    }
  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      buscador!.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);
      
      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
     
     
    }
  }

  openSearchBar2(item:any, sku:any) {
   
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
      this.cargando = true;
        this.apiProductos.esPaquete(sku).subscribe(paquetillo =>{
          this.respaq = paquetillo;
          if (this.respaq == 1) {
            this.cargando = false;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(['/descripcion-paquetes/'+item]);
          });
          }
          if (this.respaq == 0) {
            this.cargando = false;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(['/descripcion-producto/'+item]);
          });
          }
  
        
        })

    
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      this.cargando = true;
      buscador!.style.display = "none";
      this.apiProductos.getInfoPaquetes(sku).subscribe(paquetillo =>{
        this.respaq = paquetillo;
        if (this.respaq == 1) {
          this.cargando = false;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/descripcion-paquetes/'+item]);
        });
        }
        if (this.respaq == 0) {
          this.cargando = false;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/descripcion-producto/'+item]);
        });
        }

      
      })
      
    }

  }

  async modalReg() {
    this.router.navigate(['/registro']);
    /*const modal = await this.modalCtrl.create({
      component: ModalRegistroComponent,
      cssClass: 'modalRegistroCss',
      initialBreakpoint: 1,
      breakpoints: [0, 0.5, 1]
    });
    return await modal.present();*/
  }


  llevame(item:any, sku:any){
    this.apiProductos.getInfoPaquetes(sku).subscribe(paquetillo =>{
      this.respaq = paquetillo;
      if (this.respaq == 1) {
        this.cargando = false;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/descripcion-paquetes/'+item]);
      });
      }
      if (this.respaq == 0) {
        this.cargando = false;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/descripcion-producto/'+item]);
      });
      }
  
    
    })
  }

  onEnter(searchQuery: string): void {
    this.txtBuscador = '';
    if(searchQuery.length > 1){
      this.router.navigate(['/google-search/'+searchQuery], { queryParams: { query: searchQuery } });
    }
  }




  mevoy(){

    this.resultadosBusqueda= JSON.parse(localStorage.getItem('productos_buscados')!);
    this.cargando = true;
    var intervalopago = setInterval(() =>{


      this.resultadosBusqueda= JSON.parse(localStorage.getItem('productos_buscados')!);
        
        
          
            if (this.resultadosBusqueda != undefined) {
              clearInterval(intervalopago);
              var numeroAleatorio = Math.floor(Math.random() * (1 - 10000000 + 1)) + 1;
              var token = this.txtBuscador+numeroAleatorio;

              token = this.replaceAccents(token);
              token = token.replace(/[^A-Z0-9]+/ig, "_");
              this.txtBuscador = this.replaceAccents(this.txtBuscador);
              this.txtBuscador = this.txtBuscador.replace(/[^A-Z0-9]+/ig, "_");

              var datosBuscador = {
                "token": token,
                "buscado": this.txtBuscador,
                "arrayBusqueda": JSON.stringify(this.resultadosBusqueda)
              }

              this.usuarioService.registraBuscador(datosBuscador).subscribe(suscrito =>{
              
                this.txtBuscador = "";
                localStorage.removeItem('productos_buscados');
                this.apiProductos.changeParam(this.resultadosBusqueda)
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.cargando = false;
                  this.router.navigate(['/resultados-busqueda',token]);

              })

             
              
   
            
      });
              
            }
            // else{
            //     alert('Error al Procesar pago');
            //     clearInterval(intervalopago);
            // }
          
        
     
    },1000)

  
    // if (this.resultadosBusqueda == undefined) {
    //   this.estoycargando =true;
    // }else{
    //   this.txtBuscador = "";
    //   localStorage.removeItem('productos_buscados');
    //   this.apiProductos.changeParam(this.resultadosBusqueda)
    //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    //     this.router.navigate(['/resultados-busqueda']);
    //   });
    // }

  }

 Convert(string:any){
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  replaceAccents(str:any) {
    // Verifies if the String has accents and replace them
    if (str.search(/[\xC0-\xFF]/g) > -1) {
      str = str
        .replace(/[\xC0-\xC5]/g, "A")
        .replace(/[\xC6]/g, "AE")
        .replace(/[\xC7]/g, "C")
        .replace(/[\xC8-\xCB]/g, "E")
        .replace(/[\xCC-\xCF]/g, "I")
        .replace(/[\xD0]/g, "D")
        .replace(/[\xD1]/g, "N")
        .replace(/[\xD2-\xD6\xD8]/g, "O")
        .replace(/[\xD9-\xDC]/g, "U")
        .replace(/[\xDD]/g, "Y")
        .replace(/[\xDE]/g, "P")
        .replace(/[\xE0-\xE5]/g, "a")
        .replace(/[\xE6]/g, "ae")
        .replace(/[\xE7]/g, "c")
        .replace(/[\xE8-\xEB]/g, "e")
        .replace(/[\xEC-\xEF]/g, "i")
        .replace(/[\xF1]/g, "n")
        .replace(/[\xF2-\xF6\xF8]/g, "o")
        .replace(/[\xF9-\xFC]/g, "u")
        .replace(/[\xFE]/g, "p")
        .replace(/[\xFD\xFF]/g, "y");
    }
  
    return str;
  }


  test(){
   this.txtBuscador = "";
  }

  initMutationObserver() {
    if (isPlatformBrowser(this.platformId) && typeof MutationObserver !== 'undefined') {
      if (this.ulElement) {
        this.observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList') {
              this.checkForListItems();
            }
          });
        });

        const config = { childList: true, subtree: true };
        this.observer.observe(this.ulElement.nativeElement, config);
      }
    }
  }

  checkForListItems() {
    if (this.ulElement) {
      const ul = this.ulElement.nativeElement;
      const hasListItems = ul.getElementsByTagName('li').length > 0;
      if (hasListItems) {
        console.log('UL element has <li> elements.');
        this.banderaLi = true;
      } else {
        console.log('UL element does not have <li> elements.');
      }
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
